/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { motion } from 'framer-motion'
import { fade } from '@/utils/transitions'
import Link from '@/utils/Link'
import Button from '@/components/global/Button'

function PageNotFound() {
	return (
		<motion.div
			variants={fade}
			initial="initial"
			animate="enter"
			exit="exit"
			className="flex flex-col items-center justify-center py-12 wrapper"
		>
			<h1 className="mb-10 text-h2-fluid font-museoSans">Page not found</h1>
			<div className="flex items-center">
				<Link className="mr-8 pardotTrackClick" to="/">
					Home
				</Link>
				<Button theme="secondary" as={Link} to="/company/news/">
					Latest news
				</Button>
			</div>
		</motion.div>
	)
}

export default PageNotFound
