const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

export const fade = {
	initial: { opacity: 0 },
	enter: { opacity: 1, transition },
	exit: {
		opacity: 0,
		transition: { duration: 1.5, ...transition }
	}
}

export const containerSwipeVariants = {
	show: {
		clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
		transition: {
			delay: 0.3,
			type: 'spring',
			damping: 80,
			stiffness: 50,
			staggerChildren: 0.3,
			delayChildren: 0.2
		}
	},
	hide: {
		clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
		transition: {
			duration: 0.3
		}
	}
}

export const containerVariants = {
	show: {
		transition: {
			type: 'spring',
			damping: 100,
			stiffness: 50,
			staggerChildren: 0.3,
			delayChildren: 0.2
		}
	},
	hide: {
		transition: {
			delay: 0.23,
			duration: 0.3
		}
	}
}

export const imageVariants = {
	show: {
		clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
		transition: {
			delay: 0.3,
			duration: 0.7
		}
	},
	hide: {
		clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
		transition: {
			delay: 0,
			duration: 0.7
		}
	}
}

// polygon(69% 0, 100% 0, 100% 100%, 70% 100%);

export const imageVariantsAlt = {
	show: {
		// -1% and 101% to prevent hairline background showing through
		clipPath: `polygon(-1% 0%, 100% -1%, 100% 101%, 0% 101%)`,
		transition: {
			delay: 0.3,
			duration: 0.7
		}
	},
	hide: {
		clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
		transition: {
			delay: 0,
			duration: 0.7
		}
	}
}

export const textVariants = {
	show: { opacity: 1, y: 0, transition: { duration: 0.5 } },
	hide: { opacity: 0, y: 50 }
}

export const bannerVariants = {
	show: { opacity: 1, scale: 1, transition: { duration: 0.7, delay: 0 } },
	hide: { opacity: 0, scale: 1.2 }
}
